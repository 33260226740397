<template>
   
    <div class="footer">
      <p>
        联系方式： 0475-8227575/ 0475-8233924<font class="space">|</font>联系地址：
        内蒙古通辽市科尔沁区永安路1310号
      </p>
      <p>Copyright © 2019 通辽市英国英语语言学校.蒙ICP备19002218号</p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">

.footer {
    .space{
        padding:0 15px
    }
  position: fixed;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1000;

  font-weight: 400;
  font-size: 13px;
  color: #fff;
}
</style>